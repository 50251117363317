import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Post } from 'components/blog/content/styled';
import Link from 'components/ui/link';
import GuideArticle from 'hoc/guideArticle';
import { authorKalina as author } from 'data/authors';
import ArticlePreview from 'components/blog/blogArticlePreview';
import ImageModal from 'components/ui/image-modal';
import { frontmatter as articleDesc } from 'pages/blog/tips-to-improve-sign-up-forms-with-examples';
import {
  meta as articleDesc2,
  WhatsNext,
} from 'pages/tutorials/guide-to-ab-testing-with-livesession';
import { useSourceParams } from 'hooks/useSourceParams';
import { data } from 'data/guides/usability-testing';
import Code from 'components/help/codeSnippet';
import img1 from 'img/tutorials/form-analytics/1.png';
import img2 from 'img/tutorials/form-analytics/2.png';
import img3 from 'img/tutorials/form-analytics/3.png';
import img4 from 'img/tutorials/form-analytics/4.png';
import img5 from 'img/tutorials/form-analytics/5.png';
import img6 from 'img/tutorials/form-analytics/6.png';
import img7 from 'img/tutorials/form-analytics/7.png';
import img8 from 'img/tutorials/form-analytics/8.png';
import img9 from 'img/tutorials/form-analytics/9.png';
import img10 from 'img/tutorials/form-analytics/10.png';
import img11 from 'img/tutorials/form-analytics/11.png';
import abtestCover from 'img/tutorials/abtest.png';

const Guide = () => {
  const bannerLink = useSourceParams('banner');
  const ctaEnd = useSourceParams('cta');
  return (
    <Post>
      <p>
        Almost{' '}
        <Link
          href="https://wpforms.com/online-form-statistics-facts/"
          target="_blank"
          rel="noopener noreferrer noopener"
        >
          three-quarters
        </Link>{' '}
        of all companies use online forms. Half of them claim it’s their most effective lead
        generation tool
      </p>
      <p>
        With this tutorial, you’re going to learn how to optimize form on your website. Among other
        things, we’re going to cover:
      </p>
      <ul>
        <li>
          The term <i>form analytics</i>
        </li>
        <li>Optimization strategies for website forms</li>
        <li>Tips and tricks for analyzing forms with LiveSession</li>
      </ul>
      <div id="what-is-form-analytics" className="section-container">
        <h2>What is form analytics</h2>

        <p>
          There is one step on the way between website visit and conversion that’s often omitted.
          This step is, as you might have guessed, <strong>form completion</strong>. That’s the
          point where you can make it or break it.
        </p>
        <p>
          <strong>Form analytics</strong> can help you solve potential problems and come up with
          ideas for optimization. There are two main types of form analytics:
        </p>
        <ul>
          <li>
            <strong>Qualitative </strong>– e.g., which steps are most problematic. This kind of data
            can be retrieved from session recording apps, such as LiveSession.
          </li>

          <li>
            <strong>Quantitative</strong> – e.g., how many people have completed the form. You’ll
            find these number in tools like Google Analytics.
          </li>
        </ul>
        <p>
          Analyzing the performance of your forms is a valuable addition to your{' '}
          <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
            usability testing
          </Link>{' '}
          suite, too. This way, you may find some critical issues that stop users from continuing
          their journey.
        </p>
        <p>
          An example from our{' '}
          <Link href="/customers/rocketlink/" target="_blank" rel="noopener noreferrer">
            RocketLink case study
          </Link>{' '}
          makes a great illustration here. In that case, the users were supposed to enter their
          Facebook Pixel ID in one of the form fields. Instead, they were pasting the whole code
          snippet. Thanks to form analytics, the RocketLink team could spot this issue and take
          action right away.
        </p>

        <a
          className="action-banner"
          href={bannerLink.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Want to spot issues like these? Try LiveSession for free.
        </a>

        <p>So, how can you optimize forms on your website to avoid these issues?</p>
      </div>
      <div id="how-to-optimize-forms-on-your-website" className="section-container">
        <h2>How to optimize forms on your website</h2>

        <p>Below we’re sharing some of our best tips for website form optimization:</p>
        <h3 id="keep-it-short-and-sweet">Keep it short and sweet</h3>

        <p>
          Before you create your form, decide what kind of information is absolutely essential. The
          golden rule is not to ask your user for more data then needed. It’s not just about data
          processing: the longer the form, the higher the chance the user won’t complete it.
        </p>
        <p>
          The choice of form fields depends, obviously, on its purpose. According to BrightTALK
          trends report, 97% of B2B professionals believe that email is the most important form
          field for lead generation, followed by name and company.
        </p>
        <h3 id="less-is-more">Less is more</h3>

        <p>
          Do you really need the name, though? Even though it’s often mentioned as a best practice
          and an opportunity for personalization, it’s not absolutely necessary to ask for it.
        </p>
        <p>Have a look at this example from Netflix:</p>
        <ImageModal
          src={img1}
          alt="Form Analytics - 1"
          title="LiveSession - Form Analytics Tutorial"
        />
        <p>
          The registration is as simple as can be. At first, they’re only asking for your email
          address. The name is not yet necessary at this stage – you might as well add it later.
          This is the perfect example of a case where <em>less</em> really<em> </em>is<em> more</em>
          .
        </p>
        <h3 id="use-clues">Use clues</h3>

        <p>
          Every part of your form should serve a clear purpose. Let’s analyze this crystal clear
          example from Microsoft:{' '}
        </p>
        <ImageModal
          src={img2}
          alt="Form Analytics - 2"
          title="LiveSession - Form Analytics Tutorial"
        />
        <p>
          The key to success here is <strong>microcopy</strong>, also known as the interface text.
          It’s very simple, yet every word is used with a goal in mind:
        </p>
        <ul>
          <li>
            <strong>Header</strong> – <em>Create account</em> indicates what is about to happen
          </li>

          <li>
            <strong>Placeholder text</strong> –{' '}
            <em>
              <Link href="mailto:someone@example.com">someone@example.com</Link>
            </em>{' '}
            suggests the email form
          </li>

          <li>
            <strong>Alternatives</strong> – The user knows the two other options (using a phone
            number or a new email) they can choose from.
          </li>
        </ul>
        <p>Nothing more, nothing less.</p>
        <p>
          When creating your form, be sure to reevaluate the microcopy you use. Maybe there’s
          something you can edit out, or maybe it would be good to add some extra clues?
        </p>
        <h3 id="leverage-social-proof">Leverage social proof</h3>

        <p>
          Forms are the decision-making moments, and making a decision may need a little nudge. See
          how{' '}
          <strong>
            <Link
              href="https://www.liveagent.com/"
              target="_blank"
              rel="noopener noreferrer noopener"
            >
              LiveAgent
            </Link>
          </strong>{' '}
          leverages the power of <strong>social proof: </strong>
        </p>
        <ImageModal
          src={img3}
          alt="Form Analytics - 3"
          title="LiveSession - Form Analytics Tutorial"
        />
        <p>
          This particular sign-up form is crafted for B2B SaaS professionals. The review on the left
          side comes from the CEO of a SaaS company, and you can put a face to the name as well.
          What’s more, you can easily access hundreds of other reviews on the most popular software
          review sites.
        </p>
        <h3>Keep usability heuristics in mind </h3>

        <p>Last but not least, we’re happy to share our number one UX design tip:</p>
        <p>
          <strong>When in doubt, turn to usability heuristics. </strong>
        </p>
        <p>
          These{' '}
          <Link
            href="https://www.nngroup.com/articles/ten-usability-heuristics/"
            target="_blank"
            rel="noopener noreferrer noopener"
          >
            design principles
          </Link>{' '}
          were created by Jakob Nielsen, an acclaimed UX guru. Here are some of the most important
          ones to keep in mind when working on your forms:
        </p>
        <ul>
          <li>
            <strong>Visiblity of system status</strong> – When the form is a bit long, it’s good to
            include a progress indicator to encourage the user.
          </li>

          <li>
            <strong>Consistency and standards</strong> – Keep the names consistent throughout the
            whole process. If the action is called <em>Registration</em>, don’t switch to{' '}
            <em>Sign up</em> later on.
          </li>

          <li>
            <strong>Error prevention</strong> – As we’ve mentioned above, if a form field is
            ambivalent, you might want to include a clue in the microcopy.
          </li>
        </ul>
      </div>
      <div id="how-to-analyze-forms-with-livesession" className="section-container">
        <h2>How to analyze forms with LiveSession</h2>

        <p>
          Use the <strong>Form submit </strong>and <strong>Form value </strong>filters to analyze
          how users interact with forms.
        </p>
        <ol>
          <li>
            <p>
              Select the <strong>Form submit </strong>filter from the <strong>INTERACTIONS</strong>{' '}
              section:
            </p>

            <ImageModal
              src={img4}
              alt="Form Analytics - 4"
              title="LiveSession - Form Analytics Tutorial"
            />
            <p>
              Enter the form ID or selector. If you don’t know your form selector, you can use the{' '}
              <strong>anything</strong> operator.
            </p>
            <p>
              {' '}
              <strong>Tip: </strong>To narrow down the results, you can also use the{' '}
              <strong>Visited URL</strong> filter.
            </p>

            <ImageModal
              src={img5}
              alt="Form Analytics - 5"
              title="LiveSession - Form Analytics Tutorial"
            />
          </li>
          <li>Open recording from the list.</li>

          <li>
            <p>
              Enable event filtering on the <strong>Timeline events</strong> list, on the right side
              of the recording:
            </p>

            <ImageModal
              src={img6}
              alt="Form Analytics - 6"
              title="LiveSession - Form Analytics Tutorial"
            />

            <p>
              The matched event will be highlighted on the list. Click on it to jump to this event
              in the recording:
            </p>

            <ImageModal
              src={img7}
              alt="Form Analytics - 7"
              title="LiveSession - Form Analytics Tutorial"
            />
          </li>
          <li>
            <p>
              You can also add <strong>Error Clicks</strong> and <strong>Rage Clicks </strong>filter
              to your form submit search. Combining these filters together will help you find
              moments when your users got frustrated:
            </p>
            <ImageModal
              src={img8}
              alt="Form Analytics - 8"
              title="LiveSession - Form Analytics Tutorial"
            />
          </li>
          <li>
            <p>
              To find a form value change of a specific element, use the <strong>Form value</strong>{' '}
              filter:
            </p>
            <ImageModal
              src={img9}
              alt="Form Analytics - 9"
              title="LiveSession - Form Analytics Tutorial"
            />
            <p>
              In the example above, the search will show all sessions where the user has filled a
              form element with a <Code inline>input[name=”quantity”]</Code> selector.
            </p>
          </li>
          <li>
            <p>You can also use a filter based on typed value:</p>
            <ImageModal
              src={img10}
              alt="Form Analytics - 10"
              title="LiveSession - Form Analytics Tutorial"
            />
            <p>
              <strong>Tip:</strong> Please note that you need to enable keystroke recording to use
              this filter. Otherwise, there will be no results. You can learn how to enable
              keystroke recording{' '}
              <Link
                href="/help/how-to-record-keystrokes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </Link>
              .{' '}
            </p>
          </li>
          <li>
            <p>Once you’re done, save the filters as a segment for future use:</p>
            <ImageModal
              src={img11}
              alt="Form Analytics - 11"
              title="LiveSession - Form Analytics Tutorial"
            />
          </li>
        </ol>
        <a className="action-banner" href={ctaEnd.link} target="_blank" rel="noopener noreferrer">
          Analyze your forms and improve performance! Try LiveSession now
        </a>
      </div>
    </Post>
  );
};

const Wrapped = GuideArticle(Guide);

const meta = {
  title: 'Form analytics',
  url: '/tutorials/form-analytics/',
  description: `Online form analytics is crucial in greatly boosting lead generation. How? 
  LiveSession's advice is here to help out.
  `,
  author,
  date: new Date(2020, 8, 16),
  group: 'Tutorial',
  tutorials: true,
  customSection: (
    <WhatsNext>
      <h2 className="heading">What’s next?</h2>
      <p className="desc">
        Now you know everything you need to get started with form analytics. For more inspiration,
        be sure to check out these resources:
      </p>
      <div className="article-container">
        <ArticlePreview {...articleDesc} isList />
        <ArticlePreview {...articleDesc2} img={abtestCover} isList />
        <ArticlePreview {...data[0]} img={data[0].cover} isList />
      </div>
    </WhatsNext>
  ),
};

export { meta };

export default () => {
  const { cover } = useStaticQuery(graphql`
    query CoverFormAnalytics {
      cover: file(relativePath: { eq: "tutorials/formanalytics.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Wrapped
      title={meta.title}
      description={meta.description}
      date={meta.date}
      author={meta.author}
      url={meta.url}
      group={meta.group}
      img={cover.childImageSharp.fluid}
      tutorials={meta.tutorials}
      customSection={meta.customSection}
    />
  );
};
