import { authorAnia, authorKasia } from 'data/authors';
import Link from 'components/ui/link';
import React from 'react';
import cover from 'img/usability-testing/usability-testing-cover.png';

export const data = [
  {
    url: '/usability-testing/',
    title: 'Introduction',
    desc: 'All you need to know about usability testing',
    metaDesc:
      'Learn all you need to know about effective usability testing. From asking the right usability questions to creating a report.',
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2020',
    heading: (
      <>
        <p>
          Do you know that{' '}
          <Link
            href="https://www.cbinsights.com/research/startup-failure-reasons-top/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            42% of startups fail
          </Link>{' '}
          due to the lack of product-market fit? It’s not particularly surprising as building
          products is complex and requires a lot of decision making. However, since products are
          built to solve specific problems, it’s only natural to involve your users or customers in
          the decision-making process to ensure that your product perfectly meets their needs. This
          can be done through regular usability testing.
        </p>
        <p>
          In this usability testing guide, we’re going to talk about what usability testing is, how
          it can benefit your business, what are the different types of usability testing, as well
          as discuss how you can effectively conduct website and mobile usability tests. We will
          also teach you how to master usability reporting and provide you with a few tools to make
          the entire process easier.
        </p>
        <p>Let’s get started, shall we?</p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  {
    url: '/usability-testing/what-is-usability-testing/',
    title: 'Chapter 1',
    desc: 'What is usability testing? Definition and a review of the best methods',
    metaDesc: `What is the best way to improve your user experience? Conducting usability testing! Find out what it is, what benefits it brings, and what methods to use.`,
    timeToRead: 7,
    author: authorKasia,
    date: 'June 09, 2020',
    heading: (
      <>
        <p>
          Creating a product that resonates with its end-users is crucial to a company’s success. In
          fact,{' '}
          <Link
            href="https://startupdevkit.com/chapter-1-no-market-need-product-market-fit/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            42% of startups fail
          </Link>{' '}
          due to the lack of product-market fit. How can you make sure you don’t follow in their
          steps? By conducting usability testing of your product. What is usability testing? To put
          it simply, it’s the process of gathering data on how your end-user interacts with your
          product, so you can understand its strengths, weaknesses, and sticking points. In the
          following article, we’re going to cover several usability topics and methods, including
          the benefits of usability testing, types of usability testing, and the different methods
          used to improve user experience.
        </p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  {
    url: '/usability-testing/usability-testing-questions/',
    title: 'Chapter 2',
    metaDesc: `Planning to conduct user tests of your website or app? Find out what usability testing questions to ask to ensure you get the best results.`,
    desc: '24 Usability Testing Questions You Need To Ask Your Audience',
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2020',
    heading: (
      <>
        <p>
          Understanding who your audience is is essential if you want to create products that not
          only help your users achieve their goals, but are also easy and enjoyable to use. Apart
          from observing how your customers interact with your product, the best thing you can do to
          improve UX is to ask the right usability testing questions.
        </p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  {
    url: '/usability-testing/usability-reporting/',
    title: 'Chapter 3',
    desc: 'How to Become Great at Usability Reporting',
    metaDesc: `Everything you should know about usability reporting: what it is, what metrics to focus on, and what to include in your usability report.`,
    timeToRead: 8,
    author: authorAnia,
    date: 'June 11, 2020',
    heading: (
      <>
        <h2>The Key to Launching a Successful Product</h2>
        <p>
          Brilliant ideas are the backbone of great products. Most products are developed with an
          intent to provide a solution or cater to a niche market. However, only a handful of great
          products ever reach the top.{' '}
        </p>
        <p>
          So, what is the secret behind reaching that top spot? It is quite simple –conducting
          well-planned usability testing and reporting to deliver a premium end-user experience.
          According to research, 70% of CEOs believe that impressive UX and CX offers a great
          competitive advantage to businesses.{' '}
        </p>
        <p>
          In the following article, we are going to talk about what entails a usability testing
          report, which metrics to pay attention to, as well as a few tips on how to craft it
          efficiently.{' '}
        </p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  // {
  //   url: '/usability-testing/usability-reporting/',
  //   title: 'Chapter 3',
  //   desc: 'How to become great at usability reporting',
  //   timeToRead: 7,
  //   author: authorAnia,
  //   date: 'June 09, 2020',
  //   button: {
  //     text: 'Improve usability testing',
  //   },
  // },
  {
    url: '/usability-testing/usability-testing-tools/',
    title: 'Chapter 4',
    desc: '9 Usability Testing Tools You Need To Optimize Your UX',
    metaDesc: `What’s the secret to deriving insights on your users’ in-app or on-site behavior? Using the right usability testing tools. Find out which tools to choose.`,
    timeToRead: 8,
    author: authorKasia,
    date: 'June 09, 2020',
    heading: (
      <>
        <p>
          What’s the secret sauce to deriving insights on your users’ in-app or on-site behavior?
          Using the right usability testing toolkit!{' '}
          <span role="img" aria-label="emoji">
            💪
          </span>
        </p>
        <p>
          There’s a whole range of tools you can choose from – from session recording software like
          LiveSession, A/B testing tools like Unbounce, all the way through powerful analytics
          software like Google Analytics and Mixpanel. While the exact configuration comes down to
          personal preference, it’s important to cover three areas: live session recording, A/B
          testing and reporting & analytics.
        </p>
        <p>
          In today’s article, we talk about 9 usability testing tools that will help you optimize
          your user experience and drive more engagement. The link to the post is in the comments.
        </p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  {
    url: '/usability-testing/website-user-testing/',
    title: 'Chapter 5',
    desc: 'The benefits of website user testing and how to do it right',
    metaDesc: `How to ensure flawless UX on your website? Among others, by running website user testing. Find out how, why, and when to run usability tests on your site.`,
    timeToRead: 7,
    author: authorKasia,
    date: 'June 09, 2020',
    heading: (
      <>
        <h2>Testing a website for usability is a necessity, not an option </h2>
        <p>
          Website development is a lengthy but exciting process. From content development, planning,
          designing prototypes to launching the website, it involves layers of crucial steps. But
          the most important step before launching a website is to test its efficacy and
          functionality. There is no better way to do it than to put yourself in the end-users’
          shoes. In this article, we are going to explore the benefits of website user testing and
          how to perform it in the best way possible.
        </p>
      </>
    ),
    button: {
      text: 'Improve usability testing',
    },
    cover,
  },
  {
    url: '/usability-testing/mobile-usability-test/',
    title: 'Chapter 6',
    desc: 'What is a mobile usability test and do you need it?',
    metaDesc:
      'Conducting mobile usability tests is necessary to ensure your users return to the app. Find out how to test your apps effectively and what tools to use.',
    timeToRead: 7,
    author: authorAnia,
    date: 'June 09, 2020',
    button: {
      text: 'Improve usability testing',
    },
    heading: (
      <>
        <p>
          According to{' '}
          <Link
            href="https://buildfire.com/app-statistics/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Buildfire
          </Link>
          , a leading mobile app builder platform, an astounding 2.8 million mobile apps are offered
          in the Google Play Store, whereas 2.2 million apps are available for download in the iOS
          App Store.
        </p>
        <p>
          With such a colossal competition, chances of your mobile app topping the charts are quite
          slim. However, there are ways to beat the competition. Applications that bring forth
          innovative concepts and creative solutions for the digital world can easily gain
          popularity.
        </p>
        <p>
          Another important factor that garners success is ensuring your app provides a premium user
          experience. If the end-users are engaged, they will keep coming back for more.
        </p>
        <p>
          But how can we achieve all this in the advanced world of mobile app development? The
          answer is simple – by conducting mobile usability testing.
        </p>
        <p>
          Before you embark on the exciting journey of mobile usability testing, let us discuss what
          a mobile usability test is, how it benefits your business, and how to conduct it. We will
          also talk about five recommended usability testing tools that might come in handy during
          app testing.
        </p>
      </>
    ),
    cover,
  },
];
